<template>

  <div class="status-component overflow-hidden relative">
    <!--<Image v-if="imageIcon" :src="imageIcon" alt="Logo" class="status-profile-icon absolute overflow-hidden border-circle border-3 border-white z-1" imageClass="max-w-full h-auto block" />-->
    <div class="circle  absolute overflow-hidden">
      <img :src="(!imageIcon || imageIcon === 'null') ? $root.empresa.foto : imageIcon" alt="imageIcon" />
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="enable-background: new -580 439 577.9 194"
        xml:space="preserve">
        <circle cx="50" cy="50" r="40" />
      </svg>
    </div>
    <Image :src="imageStatus" :preview="false" alt="Status" class="image-status block" imageClass="max-w-full h-auto block"
      @click="$router.push({ name: 'mostrastorys', params: { id: '0', }, })" />
  </div>

</template>

<script>

export default {
  name: "Status",
  props: {
    value: Object,
  },
  data: function () {
    return {
      imageStatus: this.value.imagem,
      imageIcon: this.value.icone,

    }
  },
  mounted() {
    const container = this.$el.getElementsByClassName("image-status")[0];
    const img = container.getElementsByTagName("img")[0];
    img.onload = function() {
      if(img.naturalWidth > img.naturalHeight) {
        container.classList.add("status-ajuste");
      } else {
        container.classList.remove("status-ajuste");
      }
    };
  }
}
</script>

<style lang="scss" scoped>
.status-profile-icon {
  top: 0.5rem;
  left: 0.5rem;
  width: 50px;
  max-width: 50%;
}

.status-component {
  border-radius: 0.6rem;
  height: var(--h16x9);
}

</style>