<template>
  <div>
    <!--  <h5>Stories</h5>
     Storie buttons 
     <button
      class="btn btn-primary me-2"
      v-for="(story, index) in stories"
      :key="index"
      @click="storyClicked(index)"
    >
      {{ index }}
    </button>

    <h5>Actions</h5>
    Actions 
    <Button class="btn btn-danger" @click="stopStory">Stop</Button>
    <Button class="btn btn-danger" @click="resetStory">Reset</Button>
    <Button class="btn btn-success" @click="playStory">Play</Button>
    <Button class="btn btn-secondary" @click="prevStory">Previous Story</Button>
    <Button class="btn btn-secondary" @click="nextStory">Next Story</Button>
    <button class="btn btn-secondary" @click="prevSlide">Prev Slide</button>
    <button class="btn btn-secondary" @click="nextSlide">Next Slide</button>
    <button class="btn btn-secondary" @click="recalculateDimensions">
      Recalculate Dimensions
    </button>-->

    <!-- Stories View -->
    <div class="overlay" v-show="showSlider">
      <div
        class="stories_wrapper"
        :style="{
          width: '550px',
          height: '1080px',
          'max-width': '100%',
          'max-height': '100%',
        }"
      >

        <!-- 
          EVENTOS REMOVIDOS DO COMPONENTE STORIES:

          @prev_slide="prevSlideEvent"
          @next_slide="nextSlideEvent"
          @slide_changed="slideChangedEvent"
        -->

        <stories
          :autoplay="false"
          :duration="duration"
          :stories="stories"
          ref="stories_component"
          @ended="endedEvent"
          @next_story="nextStoryEvent"
          @prev_story="prevStoryEvent"
          @swipe_up="swipe_up"
          @swipe_down="swipe_down"
          @swipe_left="swipe_left"
          @swipe_right="swipe_right"
        >
          <template v-slot:slide="{ story, slide }">
            <!-- Stories Header -->
            <div class="header">

              <div class="avatar circle overflow-hidden">
                <img :src="(!slide.icone || slide.icone === 'null') ? $root.empresa.foto : slide.icone" alt="imageIcon" />
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style="enable-background: new -580 439 577.9 194"
                  xml:space="preserve">
                  <circle cx="50" cy="50" r="40" />
                </svg>
              </div>

              <div class="vendedor">
                {{ slide.nome }}
              </div>

            </div>
            <!-- /.header -->
            <div class="slide">
              <div v-if="typeof slide.video != 'undefined' && slide.video != ''">
                <VuePlayerVideo
                  :ref="'player_' + story.idx + '_' + slide.idx"
                  :src="slide.video"
                  :controls="false"
                  :autoplay="false"
                />
              </div>
              <div v-else>
                <Image :src="slide.image" alt="" />
              </div>
            </div>
          </template>
        </stories>
      </div>
    </div>
    <!-- /.overlay -->

  </div>
</template>

<script>
export default {
  name: "StorysInsta",
  props: {
    value: Object,
  },
  setup() {
    const stories_component = null;
    return {
      stories_component,
    };
  },
  data() {
    return {
      playing: true,
      showSlider: false,
      duration: 500,
      stories: [
        /*{
          idx: 0,
          slides: [
            {
              idx: 0,
              color: "#D53738",
              duration: 13000,
              image:
                "https://i.pinimg.com/originals/89/de/5e/89de5ef93aa8b171c48818e0404b443d.jpg",
              video:
                "https://firebasestorage.googleapis.com/v0/b/npc-store.appspot.com/o/storys%2F1650776944060.mp4?alt=media&token=26d9ad03-4fa8-4d20-935f-db89a650460b",
            },
          ],
        },
        {
          idx: 1,
          slides: [
            {
              idx: 0,
              color: "#638867",
              duration: 58000,
              image:
                "https://i.pinimg.com/originals/90/6e/9f/906e9f31df45658088049e29f35ebd0a.jpg",
              video:
                "https://firebasestorage.googleapis.com/v0/b/npc-store.appspot.com/o/storys%2F1650776591519.mp4?alt=media&token=935b3c82-5fa3-4c46-be4c-e24b37dddf9b",
            },
          ],
        },
        {
          idx: 2,
          slides: [
            {
              idx: 0,
              color: "#DAF7A6",
              duration: 58000,
              video:
                "https://firebasestorage.googleapis.com/v0/b/npc-store.appspot.com/o/storys%2F1650776591519.mp4?alt=media&token=935b3c82-5fa3-4c46-be4c-e24b37dddf9b",
            },
          ],
        },
        */
      ],
    };
  },
  created() {
    
    if (typeof this.value != "undefined") {
      let i = 0;
      for (let s of this.value) {
        this.stories.push({
          idx: i++,
          slides: [
            {
              idx: 0,
              color: "#1564c0",
              duration: s.duracao,
              icone: s.usuario.foto,
              image: s.thumbnail,
              video: s.video,
              nome: s.usuario.nome,
            },
          ],
        });
      }
      //console.log( this.stories)
    }

  },
  mounted() {
    //console.log(this.value)
    this.storyClicked(this.$route.params.id);

  },
  methods: {

    stopPlayers() {
      if(typeof this.$refs != "undefined") {
        for(const p in this.$refs) {
          if(p.indexOf("player_") != -1 && typeof this.$refs[p] != "undefined") {
            this.$refs[p].stop();
          }
        }
      }
    },

    //Actions
    storyClicked(index) {
      this.showSlider = true;
      this.stories_component.recalculateDimensions();
      this.stories_component.playStory(index);

      if(typeof this.$refs['player_' + index + '_0'] != 'undefined')
        this.$refs['player_' + index + '_0'].play();
    },
    recalculateDimensions() {
      this.stories_component.recalculateDimensions();
    },
    stopStory() {
      this.stopPlayers();
      this.stories_component.stopStory();
      this.showSlider = false;
    },
    resetStory() {
      this.stories_component.resetStory();
    },
    playStory() {
      this.stories_component.playStory();
    },
    prevStory() {
      this.stories_component.prevStory();
    },
    nextStory() {
      this.stories_component.nextStory();
    },
    /*prevSlide() {
      this.stories_component.prevSlide();
    },
    nextSlide() {
      this.stories_component.nextSlide();
    },*/

    //Events

    endedEvent() {
      this.stopPlayers();
      this.stopStory();
      this.showSlider = false;

      this.$router.push("/");
      // console.log("endedEvent");
    },

    prevStoryEvent(index) {
      this.stopPlayers();
      if(typeof this.$refs['player_' + parseInt(index) + '_0'] != 'undefined')
        this.$refs['player_' + parseInt(index) + '_0'].play();
      // console.log("%cprevStoryEvent: " + index, "color:red");
    },
    nextStoryEvent(index) {
      this.stopPlayers();
      if(typeof this.$refs['player_' + parseInt(index) + '_0'] != 'undefined')
        this.$refs['player_' + parseInt(index) + '_0'].play();
      // console.log("%cnextStoryEvent: " + index, "color:green");
    },
    // prevSlideEvent(index) {
    //   console.log("prevSlideEvent: " + index);
    // },
    // nextSlideEvent(index) {
    //   console.log("nextSlideEvent: " + index);
    // },
    // slideChangedEvent(index) {
    //   console.log("slideChangedEvent: " + index);
    // },
    swipe_left() {
      // console.log("swipe_left Event");
      this.stories_component.nextStory();
    },
    swipe_right() {
      // console.log("swipe_right Event");
      this.stories_component.prevStory();
    },
    swipe_up() {
      // console.log("swipe_up Event");
    },
    swipe_down() {
      // console.log("swipe_down Event");
      this.stopStory();
      this.showSlider = false;
    },
  },
};
</script>
<style lang='scss'>

.overlay {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(29, 29, 29);
  .stories_wrapper {
    width: auto;
    height: 100vh;
    outline: 1px solid var(--secondaryColor, $secondaryColor);
    margin: 0px auto;
    .slide {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.vue3-player-video .rounded-3xl {
  border-radius: 0px !important;
}

.header {
  padding-left: 0.2rem;
  background: rgba(29,29,29, 0.3);
  position: fixed;
  z-index: 99999;
  width: 100%;
  padding-top: 1.2rem;

  .avatar {
    display: inline-block;
    vertical-align: middle;
  }

  .vendedor {
    display: inline-block;
    vertical-align: middle;
    color: white;
    font-size: 1.3em;
    margin-left: 0.5rem;
    width: calc(100% - 75px);
  }
}

</style>